/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@include mat.core();
@include mat.legacy-core();

$custom-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$indigo-palette, 500),
    accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
  ),
  density: 0,
));

@include mat.all-component-themes($custom-theme);
@include mat.all-legacy-component-themes($custom-theme);
